import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    ActivityIndicator,
    TouchableOpacity,
    Dimensions,
} from 'react-native';

import { useMediaQuery } from 'react-responsive';

import FileSaver from "file-saver";
import XLSX from "xlsx";

import { ScreenHeader, Dropdown2, InputWithName2, GradientButton3 } from '../../components';
import styles from './style';
import { EDColors } from '../../constants/Colors';

import {
  getLevel,
  getSectionData,
  getQuesDataByLevelAndSection,
  addNewItem,
  deleteItem,
  updateItem,
  getTotalSectionData,
  addNewItemToSection
} from '../../actions/OfflineAction';
import Answer from '../answer';
import { FontAwesome } from '@expo/vector-icons';
import { ScrollView } from 'react-native-gesture-handler';
import AddModal from '../answer/addModal';
import AlertModal from '../answer/AlertModal';

// import {level1QuesData} from '../../mockup/level1';
// import {sectionDataForLevel1} from '../../mockup/section1';

const Study = ({ navigation }) => {
  const [renderData, setRenderData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [curIndex, setCurrentIndex] = useState(0);
  const [curSectionIdx, setCurSectionIdx] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [questionLen, setQuestionLen] = useState(0);
  const [quesData, setQuesData] = useState([]);
  const [renderQuesData, setRenderQuesData] = useState([]);
  const [totalSecData, setTotalSecData] = useState([]);

  const [window, setHeight] = useState(Dimensions.get('window'));
  const [modalVisible, setModalVisible] = useState(false);
  const [qNo, setQNo] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange)
    }
  })
  useEffect(() => {
    getLevelData();
    return () => {
      setQuesData(null);
    }
  }, [])

  const onChange = ({window, screen}) => {
    setHeight(window);
  }
    
  const getLevelData = async () => {
    getLevel().then((level) => {
      setData(level);
      
      getTotalSectionData().then((res) => {
        const s1 = res.filter((_) => _.LevelId === 1)
        const s2 = res.filter((_) => _.LevelId === 2)
        const s3 = res.filter((_) => _.LevelId === 3)
        const s4 = res.filter((_) => _.LevelId === 4)
        setTotalSecData([s1, s2, s3, s4]);
        getSectionDataByLevel(1)
      }).catch((err) => {});
    }).catch((err) => {
      alert("Please login or check your network status.\n" + err);
      navigation.pop();
    });
  }

  const getSectionDataByLevel = (id) => {
    getSectionData(id).then((section) => {
      setSectionData(section, id);
      getQuestionData(id, parseInt(curSectionIdx) === -1 ? "" : section[curSectionIdx].SectionId);
    }).catch(err => {
      console.log(err);
    })
  }

  const setData = (arr) => {
    const newLevelData = {
      LevelId: 4,
      Name: "Technician License (2022 - 2026)",
      ShortDesc: "Beginners start here!",
      Status: true,
      id: "uDBNGCjyqit98hECx24v-new"
    };

    setRenderData([...arr, newLevelData]);
  }

  const _handleSelectItem = (index) => {
    setRenderQuesData([]);
    setLoading(true);
    setCurrentIndex(index);
    setQNo("");

    getSectionDataByLevel(parseInt(index)+1);
  }

  const _handleSelectSectionItem = (index) => {
    setLoading(true);
    setCurSectionIdx(index);
    setQNo("");
    if( parseInt(index) === -1 ){
      getQuestionData(parseInt(curIndex)+1, "");
    } else {
      getQuestionData(parseInt(curIndex)+1, sectionData[index].SectionId);
    }
  }

  const getQuestionData = (lid, sId) => {
    getQuesDataByLevelAndSection(lid, sId).then((res) => {
      if( res ){
        setQuestionLen(res.length);
        setQuesData(res);
        setRenderQuesData(res);
        setLoading(false);
      }
    }).catch((err) => {})
  }

  const handleAddItem = (item) => {
    setLoading(true);
    addNewItem(item).then((res)=>{
      if(res) {
        getQuestionData(parseInt(curIndex)+1, parseInt(curSectionIdx) === -1 ? "" : sectionData[curSectionIdx].SectionId);
        setModalVisible(false);
        alert("New item added!");
      }
    })
  }

  const handleUpdate = (id, item) => {
    setLoading(true);
    updateItem(id, item).then((res)=>{
      if(res) {
        getQuestionData(parseInt(curIndex)+1, parseInt(curSectionIdx) === -1 ? "" : sectionData[curSectionIdx].SectionId);
        alert("Update succeed!");
      }
    })
  }
  const handleDelete = (id) => {
    setLoading(true);
    deleteItem(id).then((res)=>{
      if(res) {
        getQuestionData(parseInt(curIndex)+1, parseInt(curSectionIdx) === -1 ? "" : sectionData[curSectionIdx].SectionId);
        alert("Delete succeed!");
      }
    })
  }

  const _handleSearch = async (txt) => {
    setQNo(txt.toUpperCase());
    if( txt.length < 2 ) return;
    const temp = await quesData.filter((_) => _.QNo.includes(txt.toUpperCase()) || _.QNo.includes(txt.toLowerCase()));
    setRenderQuesData(temp);
  }

  const handleExportToCSV = async () => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".csv";
    const levelIndex = parseInt(curIndex) + 1;
    const sectionIndex = parseInt(curSectionIdx);
    const fileName = sectionIndex === - 1? "Question_" + `${levelIndex}`: "Question_" + `${levelIndex}_` + `${sectionIndex}`;
    
    const temp = [];
    await quesData.forEach((item, index) => {
      temp.push({
        Level: item.LevelId,
        QNo: item.QNo,
        Section: item.SectionId,
        Question: item.Question,
        AnswerA: item.AnswerA,
        AnswerB: item.AnswerB,
        AnswerC: item.AnswerC,
        AnswerD: item.AnswerD,
        "Correct Answer": item.Correctanswer,
        Help: item.Help,
        "Is Image Question": item.IsImageQuestion,
        "Image Name": item.imageName,
      })

      if( index === quesData.length - 1 ) {
        const ws = XLSX.utils.json_to_sheet(temp);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array"});
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    });
  }

  // const handleAddLevel4Data = async () => {
    // setLoading(true);
    // const tempData = level1QuesData.data;
    // tempData.forEach((_item, index) => {
    //   const addItem = {
    //     ..._item,
    //     LevelId: 4
    //   }
    //   addNewItem(addItem).then((res)=>{
    //     if(res) {
    //       console.log("Added new item ::", index);
    //     }
    //   })
    //   if (tempData.length - 1 === index) {
    //     console.log("========= all data added successfull =========")
    //     setLoading(false);
    //   }
    // })
  // }

  // const handleAddSectionDataForLevel4 = async () => {
  //   setLoading(true);
  //   sectionDataForLevel1.forEach((_item, index) => {
  //     addNewItemToSection(_item).then((res)=>{
  //       if(res) {
  //         console.log("Added new item ::", index);
  //       }
  //     })
  //     if (sectionDataForLevel1.length - 1 === index) {
  //       console.log("========= all data added successfull =========")
  //       setLoading(false);
  //     }
  //   })
  // }

  return (
    <View style = {[styles.container, {maxHeight: window.height}]}>
      {/* <GradientButton3 
        text="Add Sectio Data For Level4"
        handlePress={()=>handleAddSectionDataForLevel4()}
      /> */}
      <ScrollView >
        <View style={styles.mainbody}>
          <ScreenHeader text = "Question Pool Admin" isMobile={isMobile}/>
          <View style={styles.body}>
            {loading || renderData.length === 0? 
              <ActivityIndicator size="large" color = {EDColors.gradient[2]}/>:
              <>
                <View style={[styles.flatCon, ]}>
                  <Text 
                    style={
                      [styles.quelenTxt,
                        {
                          paddingHorizontal: isMobile? 10 : 0
                        }
                      ]
                    }
                  >Total Question Count for Current Section: <Text style={{color: EDColors.gradient[2], marginRight:3}}>{questionLen}</Text>{isMobile? `Qs`:`Questions`}</Text>
                  <View 
                    style={[
                      styles.selectCon,
                      {
                        flexDirection: isMobile? "column": "row",
                      }
                    ]}
                  >
                    <Dropdown2
                      isMobile={isMobile}
                      data={renderData}
                      handlePress={(_h) => _handleSelectItem(_h)}
                      text="Select Level:"
                      curIndex={curIndex}
                    />
                    <Dropdown2
                      isMobile={isMobile}
                      data={sectionData}
                      blank
                      handlePress={(_h) => _handleSelectSectionItem(_h)}
                      text="Select Section:"
                      curIndex={curSectionIdx}
                    />
                  </View>
                  <InputWithName2
                    isMobile={isMobile}
                    label={"Search by Question Number"}
                    placeholder={"QNo..."} 
                    value={qNo}
                    onChangeText={(txt)=>_handleSearch(txt)}
                  />
                  <GradientButton3 
                    text="Export to CSV"
                    handlePress={()=>handleExportToCSV()}
                  />
                </View>
                <View  style={styles.subCon}>
                  <Answer
                    updateItem={(_id, _item) => handleUpdate(_id, _item)}
                    deleteItem={(_id) => {
                      if( modalVisible ) return;
                      setDeleteId(_id);
                      setAlertModal(true);
                    }}
                    levelData={renderData}
                    isMobile={isMobile}
                    sHeight = {window.height}
                    quesData={renderQuesData}
                    sectionData={totalSecData}
                  />
                </View>
              </>
            }
          </View>
        </View>
      </ScrollView>
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={()=>setModalVisible(true)}
        style={[styles.fabBtn, {top: window.height - 80}]}
      >
        <FontAwesome
          name="plus"
          color={EDColors.white}
          size={30}
        />
      </TouchableOpacity>
      <View style={styles.modalCon}>
        <AddModal
          modalVisible={modalVisible}
          onClose={()=>setModalVisible(false)}
          width={isMobile? "90%": "65%"}
          sWidth={window.width}
          sHeight = {window.height}
          levelData={renderData}
          isMobile={isMobile}
          quesData={quesData}
          sectionData={totalSecData}
          addNewItem={(item) => handleAddItem(item)}
        />
        <AlertModal
          width={isMobile? "80%": "50%"}
          sWidth={window.width}
          sHeight = {window.height}
          msg={"Are you sure?"}
          isMobile={isMobile}
          modalVisible={alertModal}
          deleteItem={() => {
            handleDelete(deleteId);
            setAlertModal(false);
          }}
          onClose={()=>setAlertModal(false)}
        />
      </View>
    </View>
  )
};

export default Study;
