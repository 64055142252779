import * as React from 'react';
import { 
    View,
    Text,
    TextInput,
    StyleSheet,
} from 'react-native';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';

const InputWithName2 = ({label, placeholder, value, onChangeText, isMobile}) => {
    
    return (
        <View style={[styles.container]} key={label}>
            <Text style={isMobile? styles.label1 : styles.label}>{`${label}: `}</Text>
            <TextInput 
                style={styles.input}
                placeholder = {placeholder}
                placeholderTextColor={EDColors.grey}
                value = {value}
                keyboardType = {"default"}
                onChangeText={(text)=> onChangeText(text)}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex:1,
        height: 40,
        flexDirection:'row',
        alignItems:'center',
        borderRadius: 5,
        marginVertical: 10,
    },
    input:{
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.small,
        textAlignVertical:'center',
        paddingRight: 5,
        paddingVertical: 5,
        flex: 1,
        width: "100%",
        paddingHorizontal: Units.isTablet? 20: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: EDColors.topbar,
        backgroundColor: EDColors.white,
    },
    label: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
        color: EDColors.gradient[0],
        minWidth: 140,
    },
    label1: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
        color: EDColors.gradient[0]
    },
})
export default InputWithName2;