import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Picker } from '@react-native-community/picker';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';

const Dropdown = ({isMobile, data, handlePress, text, curIndex, blank, section}) => {
        return (
            <View 
                style={[styles.selectSubCon, {
                    alignItems: isMobile? "flex-start": "center",
                    justifyContent: isMobile? "space-between": "flex-start",
                    flexDirection: isMobile? 'column': 'row'
                }]} 
                key={text}
            >
                <View style={{flexDirection: isMobile? "row": "column", alignItems: isMobile? 'flex-start' :'flex-end'}}>
                    <Text style={isMobile? styles.quelenTxt1: styles.quelenTxt}>{text}</Text>
                    {curIndex === -1 && isMobile && text !== "ImageName: " && <Text style={styles.valTxt}>{`This field is required.`}</Text>}
                </View>
                <View style={{flex: 1, width: "100%"}}>
                    <Picker
                        selectedValue={curIndex}
                        onValueChange={(hand) => handlePress(hand)}
                        style={[styles.picker, {
                            paddingHorizontal: isMobile? 0: 10
                        }]}
                        mode="dropdown"
                    >
                        {blank && <Picker.Item label={""} value={-1} key={"blank"}/>}
                        {
                            data.map((item, index) => {
                                if( section && index > 9 ) return null;
                                return(
                                    <Picker.Item label={section? item.SectionId : item.Name} value={index} key={index.toString()}/>
                                )
                            })
                        }
                    </Picker>
                    {curIndex === -1 && !isMobile && text !== "ImageName: " && <Text style={styles.valTxt}>{`This field is required.`}</Text>}
                </View>
            </View>
        );
}

const styles = StyleSheet.create({
    selectSubCon: {
        marginBottom: 10,
    },
    quelenTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
        minWidth: 130,
        marginRight: 10
    },
    quelenTxt1: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
    },
    picker: {
        paddingRight: 5,
        paddingVertical: 5,
        flex: 1,
        width: "100%",
        paddingHorizontal: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: EDColors.topbar,
        minWidth: 200,
    },
    valTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.small,
        color: EDColors.warning
    }
})
export default Dropdown;