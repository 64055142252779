import { StyleSheet } from 'react-native';
import { EDColors } from '../../constants/Colors';
import { em, Units } from '../../constants/Unit';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: EDColors.topbar,
        justifyContent: "center",
    },
    marktitle: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: "center",
    },
    markCon: {
        width: "100%",
        paddingVertical: 50,
        justifyContent: "center",
        alignItems: "center",
    },
    waveImg: {
        height: 80,
        width: 300,
    },
    mark: {
        width: 250,
        height: 250,
    },
    socialCon: {
        width: "100%",
        alignItems: "center",
        paddingVertical: 30,
    },
    ggBtn:{
        marginBottom: 20,
        paddingHorizontal: 20,
        width: Units.isTablet? 400: 275,
        backgroundColor: EDColors.white,
        height: Units.isTablet? 80 : 50,
        borderRadius: 5,
        alignItems: 'center',
        flexDirection: 'row',
        shadowColor: "black",
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: .5,
        shadowRadius: 5,
        elevation: 3,
    },
    facebook: {
        width: Units.isTablet? 35: 25,
        height: Units.isTablet? 35: 25,
        marginHorizontal: 10,
    },
    ggTxt: {
        fontFamily: Units.ftFamily.KarlaB,
        fontSize: Units.ftSizes.default,
        letterSpacing: Units.isTablet? 4: 1,
        marginLeft: 5,
    },
});

export default styles;

