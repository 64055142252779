import * as React from 'react';
import { 
    View,
    Text,
    TextInput,
    StyleSheet,
} from 'react-native';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';

const InputWithName= ({label, placeholder, value, onChangeText, isMobile}) => {
    const oneLine = label.includes("Question Number");

    return (
        <View style={[styles.container, {
            
            alignItems: isMobile? "flex-start": "center",
            justifyContent: isMobile? "space-between": "flex-start",
            flexDirection: isMobile? 'column': 'row'
        }]} key={label}>
            <View style={{flexDirection: isMobile? "row": "column", alignItems:isMobile? 'flex-start' : 'flex-end'}}>
                <Text style={isMobile? styles.label1 : styles.label}>{`${label}: `}</Text>
                {value === "" && isMobile && label !== "Explanation" && <Text style={styles.valTxt}>{`This field is required.`}</Text>}
            </View>
            <View style={oneLine? {flex: 1, width: '100%'} : {flex: 1, width: '100%', height: 60}}>
                <TextInput 
                    style={[styles.input, {}]}
                    placeholder = {placeholder}
                    placeholderTextColor={EDColors.grey}
                    value = {value}
                    multiline={true}
                    keyboardType = {"default"}
                    onChangeText={(text)=> onChangeText(text)}
                />
                {value === "" && !isMobile && label !== "Explanation" && <Text style={styles.valTxt}>{`This field is required.`}</Text>}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex:1,
        // height: 40,
        flexDirection:'row',
        alignItems:'center',
        borderRadius: 5,
        marginBottom: 10,
    },
    input:{
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.small,
        textAlignVertical:'center',
        paddingRight: 5,
        paddingVertical: 5,
        flex: 1,
        width: "100%",
        paddingHorizontal: Units.isTablet? 20: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: EDColors.topbar,
    },
    label: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
        minWidth: 140,
    },
    label1: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
    },
    valTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.small,
        color: EDColors.warning
    }
})
export default InputWithName;