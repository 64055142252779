import AsyncStorage from '@react-native-community/async-storage';
// var RNFS = require('react-native-fs');

export const getData = async (key) => {
  try {
      const jsonValue = await AsyncStorage.getItem(key)
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch(e) {
      // error reading value
    }
  }
  
export const storeData = async (key, value) => {
    try {
      const jsonValue = JSON.stringify(value)
      await AsyncStorage.setItem(key, jsonValue)
    } catch (e) {
      // saving error
    }
}

export const removeData = async (key) => {
    try {
        await AsyncStorage.removeItem(key);
    } catch (e){

    }
}

export const storeOneData = async (key, value) => {
    try {
      await AsyncStorage.setItem(key, value)
    } catch (e) {
      // saving error
    }
} 
  
export const getOneData = async (key) => {
    try {
      const value = await AsyncStorage.getItem(key)
      if(value !== null) {
        return value;
      }
    } catch(e) {
      // error reading value
    }
}


// export const checkStoreData = () => {
//   createDirectory('HamRadioPrepOfflineData');
// }

// const createDirectory = async (name) => {
//     try {
//       await RNFS.readDir(RNFS.DownloadDirectoryPath)
//           .then((result) => {
//               let path = RNFS.DownloadDirectoryPath+'/'+name;
//               console.log("path ===> " + path);
//               RNFS.mkdir(path).catch((error)=>{console.log(error)})
//           }).catch((err) => {
//               console.log(err.message, err.code);
//           }
//       );
//     } catch (err) {
//       console.log(err);
//     }
// }

// const deleteDirectory = async (path) =>{
//     // delete a specified file
//     await RNFS.unlink(path)
//     .then(() => {
//         console.log('FILE DELETED');
//     })
//     .catch((err) => {
//         console.log(err.message);
//     });
// }

// function getRandomInt(max) {
//     return Math.floor(Math.random() * Math.floor(max));
// }

// export const getCurrentTime = () => {
//     return Math.floor(Date.now() / 1000);
// }