import * as React from 'react';
import { StyleSheet,TouchableOpacity, Text, View } from 'react-native';
// import {} from 'react-native-gesture-handler';
import {LinearGradient} from 'expo-linear-gradient';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';

const GradientButton3 =({text, handlePress})=> {
    return (
        <TouchableOpacity style={styles.mainBtn} onPress = {() => handlePress()}>
            <LinearGradient
                start={{x: 0, y: 0}} end={{x: 1, y: 1.0}}
                style={styles.button}
                colors={EDColors.gradient}
            >
                <View style={{width: "100%"}}>
                    <Text style={styles.btnTxt}>{text.toUpperCase()}</Text>
                </View>
            </LinearGradient>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    
    mainBtn: {
        width: "90%",
        maxWidth: 350,
        minWidth: 100,
        borderRadius: 25,
        shadowOffset: { width: 1, height: 2 },
        shadowColor: "black",
        shadowOpacity: .3,
        shadowRadius: 5,
        elevation: 3,
        alignSelf: "center",
    },
    button: {
        flexDirection: 'row',
        height: 40,
        paddingHorizontal: 20,
        width: "100%",
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
        letterSpacing: 2,
        textAlign: "center",
    },
})
export default GradientButton3;