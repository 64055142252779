
import firebase from "firebase/app";
import "firebase/firestore";

import { tableNames } from '../constants/Config';

import { Units } from '../constants/Unit';
import {
    storeData,
} from './General';

import { storageKey } from '../constants/StorageKey';
import { t } from "./Type";

// ====================  Start getting level data  ======================
export function getLevel() {
    return new Promise((resolve, reject) =>{
        try{
            firebase.firestore().collection(`${tableNames.level}`).orderBy("LevelId", "asc").get().then( querySnapshot => {
                let level = [];
                let count = 0;
                if( querySnapshot !== null ){
                    querySnapshot.forEach(async documentSnapshot => {
                        count++;
                        level.push({
                            id: documentSnapshot.id,
                            ...documentSnapshot.data(),
                        });
                        if( count === querySnapshot.size ){
                            await storeData(storageKey.LEVEL_DATA, level); 
                            resolve(level);
                        }
                    });
                }
            }).catch((error) => {
                reject(error.code);
            });
        } catch (err){
            reject(err);
        }
    });
}

// start getting question data by level id 
export const getQuesDataForPracticeByLevel = (selectedLevel) => {

    return new Promise((resolve, reject) => {
        if( selectedLevel === undefined || selectedLevel === null ){
            resolve([]);
        }
        firebase.firestore().collection(`${tableNames.question}`).where("LevelId", "==", selectedLevel.LevelId).get().then( async querySnapshot => {
            let ques = [];
            let count = 0;
            if( querySnapshot !== null ){
                querySnapshot.forEach(async(documentSnapshot) => {
                    const quesitem = documentSnapshot.data();
                    ques.push({
                        id: documentSnapshot.id,
                        ...quesitem,
                        answers: Units.alpha,
                    });
                    count++;
                    if( count === querySnapshot.size ){
                        resolve(ques);
                    }
                });
                if( querySnapshot.size === 0 )
                    reject([]);
            } else {
                reject(null);
            }   
        });
    });
}

export const getSectionData = (id) =>{
    return new Promise((resolve, reject) => {
        firebase.firestore().collection(tableNames.section).where("LevelId", "==", id).get().then(querySnapshot => {
            let section = [];
            let count = 0;
            if( querySnapshot !== null ){
                querySnapshot.forEach(async function( documentSnapshot) {
                    const item = documentSnapshot.data();
                    section.push({
                        id: documentSnapshot.id,
                        ...item,
                    });
                    count++;
                    if( count === querySnapshot.size ){
                        const sortedData = section.sort(function(a, b) {
                            return a.SectionNumber - b.SectionNumber;
                        });
                        resolve(sortedData);
                    }
                });
                if( querySnapshot.size === 0 ){
                    resolve([]);
                }
            } else {
                reject(null);
            }
        });
    });
}


export const getTotalSectionData = () =>{
    return new Promise((resolve, reject) => {
        firebase.firestore().collection(tableNames.section).get().then(querySnapshot => {
            let section = [];
            let count = 0;
            if( querySnapshot !== null ){
                querySnapshot.forEach(async function( documentSnapshot) {
                    const item = documentSnapshot.data();
                    section.push({
                        id: documentSnapshot.id,
                        ...item,
                    });
                    count++;
                    if( count === querySnapshot.size ){
                        const sortedData = section.sort(function(a, b) {
                            return a.SectionNumber - b.SectionNumber;
                        });
                        resolve(sortedData);
                    }
                });
                if( querySnapshot.size === 0 ){
                    resolve([]);
                }
            } else {
                reject(null);
            }
        });
    });
}

export const getQuesDataByLevelAndSection = (lId, sId) => {
    //.orderBy("QNo", "asc")
    return new Promise((resolve, reject) => {
        firebase.firestore().collection(tableNames.question).orderBy("QNo", "asc").onSnapshot(querySnapshot => {
            let section = [];
            let count = 0;
            if( querySnapshot !== null ){
                querySnapshot.forEach(async function( documentSnapshot) {
                    const item = documentSnapshot.data();
                    if( sId === ""  && item.LevelId === lId ){
                        section.push({
                            id: documentSnapshot.id,
                            ...item,
                        });
                    } else {
                        if( item.SectionId === sId && item.LevelId === lId){
                            section.push({
                                id: documentSnapshot.id,
                                ...item,
                            });
                        }
                    }
                                    
                    count++;
                    if( count === querySnapshot.size ){
                        // const sortedData = section.sort(function(a, b) {
                        //     return a.QNo - b.QNo;
                        // });
                        resolve(section);
                    }
                });
                if( querySnapshot.size === 0 ){
                    resolve([]);
                }
            } else {
                reject("error");
            }
        });
    });
}

export const setQuestion = (data) => {
    return (dispatch) => {
        dispatch({
            type: t.QUESTION_DATA_LOAD,
            payload: data,
        })
    }
}   

export const addNewItem = (item) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection(tableNames.question).add(item).then((res) => {
            if( res ){
                resolve(true);
            } else {
                reject("error");
            }
        });
    });
}

export const addNewItemToSection = (item) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection(tableNames.section).add(item).then((res) => {
            if( res ){
                resolve(true);
            } else {
                reject("error");
            }
        });
    });
}
export const updateItem = (id, item) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection(tableNames.question).doc(id).update(item).then((res) => {
            resolve(true);
        })
        .catch((err) => {
            reject(false);
        });
    });
}

export const deleteItem = (id) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection(tableNames.question).doc(id).delete().then((res) => {
            resolve(true);
        })
        .catch((err) => {
            reject(false);
        });
    });
}