import firebase from "firebase";

import {
  Units
} from "./Unit";
export const tableNames = {
  level: "Levels",
  section: "Sections",
  question: "Questions",
  lessonForRFU: "LessonForReportsForUser",
  questionForRFU: "QuestionsForReportsForUser",
  practiceForRFU: "PracticeForReportsForUser",
  userClickedOnPremium: "hasUserClickedOnPremium",
  appsFlyerUserEvent: "AppsFlyerUserEvent",
  usersForAppDownloads: "UsersForAppDownloads",
  isContentDownloaded: "IsContentDownloaded",
  inAppReview: "InAppReviewForUser",
  eventsCount: "NumberOfEvents",
};

export const webhookUrl = "https://hooks.zapier.com/hooks/catch/3401702/ox6nfdd/";

export const onlineUrl = Units.isIOS ? "https://hamradioprep.com/mobile?utm_source=ios&utm_medium=mobile&utm_campaign=react" : "https://hamradioprep.com/mobile?utm_source=android&utm_medium=mobile&utm_campaign=react";
export const findExamUrl = "https://www.arrl.org/find-an-amateur-radio-license-exam-session";
export const contactUsUrl = Units.isIOS ? "http://hamradioprep.com/mobile-app-support?utm_source=ios&utm_medium=mobile&utm_campaign=react" : "http://hamradioprep.com/mobile-app-support?utm_source=android&utm_medium=mobile&utm_campaign=react";
export const licenseUrl = Units.isIOS ? "https://hamradioprep.com/how-to-get-your-ham-radio-license-made-easy?utm_source=ios&utm_medium=mobile&utm_campaign=react" : "https://hamradioprep.com/how-to-get-your-ham-radio-license-made-easy?utm_source=android&utm_medium=mobile&utm_campaign=react";

export const firebaseConfig = {
  "apiKey": "AIzaSyD9_MiWpIejxaGd8cGVOvQI96fN4RotWcE",
  "authDomain": "american-radio-club.firebaseapp.com",
  "databaseURL": "https://american-radio-club-default-rtdb.firebaseio.com",
  "projectId": "american-radio-club",
  "storageBucket": "american-radio-club.appspot.com",
  "messagingSenderId": "1029443371652",
  "appId": "1:1029443371652:web:2724e782b619f53a5778d1",
  "measurementId": "G-XX233B1LGF"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();


export const appsflyerConf = {
  devKey: "QLNwzyRVL4FvhA5or3s6ZU",
  isDebug: false,
  appId: "id1525090989",
  onInstallConversionDataListener: false,
  onDeepLinkListener: false,
}

export const eventForPremium = {
  eventName: "premium_course",
  eventValue: {
    "content": "User visit a premium online course",
  },
}

export const eventForExam = {
  eventName: "final_exam_finished",
  eventValue: {
    "content": "User finished a first final exam",
  },
}

export const eventForQuizFinished = {
  eventName: "quiz_finished",
  eventValue: {
    "content": "User finished the quiz",
  },
}

export const videoUrl = "FindAnExam.mp4";

export const events = {
  noOfDownloads: "NumberOfDownloads",
  noOfLoginSucceed: "NumberOfLoginSucceed",
  noOfQuizFinished: "NumberOfQuizFinished",
  noOfPractice: "NumberOfPracticeFinished",
  noOfPremium: "NumberOfPremiumCourse",
}

export const storageImages = [
  "T1.jpeg",
  "T2.jpeg",
  "T3.jpeg",
  "E501.png",
  "E601.png",
  "E602.png",
  "E603.png",
  "E701.png",
  "E702.png",
  "E703.png",
  "E901.png",
  "E902.png",
  "E903.png",
  "General_Diagram.jpg",
  "FindAnExam.mp4",
];

export const ADMIN_USER = ["criticalcommunicationsmedia@gmail.com", "jamesbluebandit@yahoo.com", "dimitar@hamradioprep.com"];

export const authInfo = {
  email: "test@user.com",
  password: "123456789",
};
