import React from 'react';
import {
    View,
    FlatList,
} from 'react-native';

import styles from './style';
import QuesBlock from './quesBlock';
import { GradientButton3 } from '../../components';

const limit = 5;
class Answer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            renderData: [],
            isLoading: true,
            data: [],
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: false,
            page: 0,
            data: this.props.quesData
        }, function() {
            this.addRecords(0);
        });
    }

    componentDidUpdate(prevPros, prevState) {
        if( prevPros.quesData !== this.props.quesData ) {
            this.setState({
                isLoading: false,
                page: 0,
                data: this.props.quesData
            }, function() {
                this.addRecords(0);
            });
        }
    }

    addRecords = (_p) => {
        const newRecords = [];
        for( var i = _p*limit, i1 = i+limit; i < i1 && i < this.state.data.length ; i++ ) {
            newRecords.push(this.state.data[i]);
        }
        _p === 0 ?
        this.setState({
            renderData: [...newRecords]
        }):
        this.setState({
            renderData: [...this.state.renderData, ...newRecords]
        });
    }

    onScrollHandler = () => {
        this.setState({
            page: this.state.page + 1
        }, () => {
            this.addRecords(this.state.page);
        })
    }
    
    _mainRenderItem = ({ item, index }) => {
        const { levelData, isMobile, sectionData, deleteItem, updateItem  } = this.props;
        return <QuesBlock item={item} lData={levelData} sData = {sectionData} isMobile={isMobile} deleteItem={deleteItem} updateItem={updateItem} />
    }
    
    render() {
        const { isMobile, quesData } = this.props;
        return (
            <View style={isMobile? [styles.body, {paddingHorizontal: '5%', height: "50%"}]: [styles.body]} >
                {!this.state.isLoading && <FlatList
                    data={this.state.renderData}
                    keyExtractor={(item, index) => item.id + index.toString()}
                    renderItem = {this._mainRenderItem}
                    showsVerticalScrollIndicator={false}
                    
                />}
                {this.state.renderData.length < quesData.length && <View style={{width: isMobile? '80%': '50%', marginTop: 20, marginBottom: 80, alignSelf: 'center'}}>
                    <GradientButton3
                        text={"Load More"}
                        handlePress={this.onScrollHandler}
                    />
                </View>}
            </View>
        );
    }
    
};

export default Answer;
