import React from "react";

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

// import bottom's main screens
import LoginScreen from './screens/login';
import StudyScreen from './screens/study';

const mainStack = new createStackNavigator();
function MainStack() {
    return (
        <NavigationContainer>
            <mainStack.Navigator>
                <mainStack.Screen name = "Login" component = {LoginScreen} options = {{headerShown: false}}/>
                <mainStack.Screen name = "Admin" component = {StudyScreen} options = {{headerShown: false}}/>
            </mainStack.Navigator>
        </NavigationContainer>
    )
};

export default MainStack;